





































































import { i, string } from 'mathjs';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';

@Component({
  components: { UiSelect2 },
})
export default class UiPagination extends BaseVue {
  @Prop({ required: true })
  public readonly total!: number;

  @Prop({ required: true })
  public readonly itemsPerPage!: number;

  @Prop({ required: true })
  public readonly value!: number;

  @Prop({ default: 7 })
  public readonly totalVisible!: number | string;

  @Prop({ default: false })
  public readonly perPageControl!: boolean;

  @Prop({ default: () => [5, 10, 25, 50, 100] })
  public readonly itemsPerPageOptions!: number[];

  public get calcItemsPerPageOptions() {
    const retVal = this.itemsPerPageOptions.map((item: number) => {
      return {
        label: item.toString(),
        id: item,
      };
    }) as { label: string; id: number }[];
    retVal.push({
      label: 'All',
      id: this.total,
    });
    return retVal;
  }

  public getPageButtonClass(page: number) {
    if (page === this.value) {
      return 'tw-relative tw-z-10 tw-inline-flex tw-items-center tw-border tw-bg-neutral-400 tw-text-white tw-px-4 tw-py-2 tw-text-sm tw-font-medium focus:tw-z-20';
    } else {
      return 'tw-relative tw-inline-flex tw-items-center tw-border tw-border-gray-300 tw-bg-white tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-50 focus:tw-z-20';
    }
  }

  public range(from: number, to: number) {
    const range = [];

    from = from > 0 ? from : 1;

    for (let i = from; i <= to; i++) {
      range.push(i);
    }

    return range;
  }

  public onItemsPerPageChange(value: string | number) {
    this.$emit('changeItemsPerPage', value);
  }

  public get items() {
    const totalVisible = parseInt(String(this.totalVisible), 10);

    if (totalVisible === 0 || isNaN(this.length) || this.length > Number.MAX_SAFE_INTEGER) {
      return [];
    }

    const maxLength = Math.min(Math.max(0, totalVisible) || this.length, this.length);

    if (this.length <= maxLength) {
      return this.range(1, this.length);
    }

    const even = maxLength % 2 === 0 ? 1 : 0;
    const left = Math.floor(maxLength / 2);
    const right = this.length - left + 1 + even;

    if (this.value > left && this.value < right) {
      const firstItem = 1;
      const lastItem = this.length;
      const start = this.value - left + 2;
      const end = this.value + left - 2 - even;
      const secondItem = start - 1 === firstItem + 1 ? 2 : '...';
      const beforeLastItem = end + 1 === lastItem - 1 ? end + 1 : '...';

      return [1, secondItem, ...this.range(start, end), beforeLastItem, this.length];
    } else if (this.value === left) {
      const end = this.value + left - 1 - even;
      return [...this.range(1, end), '...', this.length];
    } else if (this.value === right) {
      const start = this.value - left + 1;
      return [1, '...', ...this.range(start, this.length)];
    } else {
      return [...this.range(1, left), '...', ...this.range(right, this.length)];
    }
  }

  onPrev() {
    if (this.value > 1) {
      this.$emit('input', this.value - 1);
    }
  }

  onNext() {
    if (this.value < this.length) {
      this.$emit('input', this.value + 1);
    }
  }

  onSelect(page: number) {
    this.$emit('input', page);
  }

  public get length() {
    return Math.ceil(this.total / this.itemsPerPage);
  }
}
