









































































































































































































import axios from 'axios';
import gql from 'graphql-tag';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { baConfig } from '@/../config';
import { Connection, TransactionDirection, Wallet } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import TxnSummaryInteractingAddress from '@/components/dashboard/txnSummary/TxnSummaryInteractingAddress.vue';
import TxnSummaryMain from '@/components/dashboard/txnSummary/TxnSummaryMain.vue';
import RuleModal2 from '@/components/rules/RuleModal2.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiButtonToggle from '@/components/ui/UiButtonToggle.vue';
import UiDatePicker2 from '@/components/ui/UiDatePicker2.vue';
import UiDatePreset from '@/components/ui/UiDatePreset.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiPagination from '@/components/ui/UiPagination.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import Beta from '@/components/util/Beta.vue';
import { TxnSummaryInteractingAddressRecord, TxnSummaryMainRecord } from '@/models/txnsSummary';
import { WalletsQuery } from '@/queries/transactionsPageQuery';
import { CancelablePromise, CanceledError, makeCancelable } from '@/utils/CancelablePromise';
import { construcRangeFilter, constructFilter, getEndpointUrl } from '@/utils/endpointUrlUtil';

type TxnSummaryViewType = 'main' | 'interacting_address';
type TxnSummaryView = { name: string; id: TxnSummaryViewType };
type TxnSummaryFilters = {
  reconciliationStatus: string;
  walletId: string[];
  assetId: string[];
  startDate: string | null;
  endDate: string | null;
};

@Component({
  components: {
    TxnSummaryInteractingAddress,
    TxnSummaryMain,
    RuleModal2,
    UiButton,
    UiButtonToggle,
    UiDatePicker2,
    UiDatePreset,
    UiSelect,
    UiSelect2,
    UiPagination,
    UiLoading,
    UiTooltip,
    Beta,
  },
  apollo: {
    wallets: {
      query: WalletsQuery,
      variables() {
        if (this.$store.state.currentOrg) {
          return {
            orgId: this.$store.state.currentOrg.id,
          };
        } else {
          return false;
        }
      },
      loadingKey: 'loadingStates.wallets',
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    },
    connections: {
      query: gql`
        query GetConnections($orgId: ID!) {
          connections(orgId: $orgId, overrideCache: true) {
            id
            provider
            lastSyncSEC
            isSetupComplete
            isDisabled
            isDeleted
            syncStatus {
              status
              lastSyncCompletedSEC
              errors
              warnings
              isRunning
            }
            name
            accountCode
            feeAccountCode
            connectionSpecificFields
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'loadingStates.connections',
    },
  },
})
export default class TxnSummaryDashboard extends BaseVue {
  public apiUrl = baConfig.apiUrl || '';
  public selectedViewId: TxnSummaryViewType = 'main';
  public views: TxnSummaryView[] = [
    { name: 'Main', id: 'main' },
    { name: 'Interacting Address', id: 'interacting_address' },
  ];

  defaultFilters: TxnSummaryFilters = {
    reconciliationStatus: 'All',
    walletId: ['All'],
    assetId: ['All'],
    startDate: null,
    endDate: null,
  };

  public filters: TxnSummaryFilters = { ...this.defaultFilters };
  public columnFilters?: { [key: string]: string[] };
  public pagination = {
    pageNumber: 1,
    pageSize: 100,
  };

  public sort?: {
    field: string;
    order: 'asc' | 'desc';
  } = {
    field: '',
    order: 'desc',
  };

  public loadingStates = {
    records: 0,
    totals: 0,
    export: 0,
    wallets: false,
    connections: false,
  };

  public isOpenRulesModal = false;
  public ruleConditions: any = null;
  public gridActions = [
    { value: 'inflow_rule', label: 'Create Rule For Inflow' },
    { value: 'outflow_rule', label: 'Create Rule For Outflow' },
    // { value: 'all_rule', label: 'Create Rule For All' },
  ];

  public connections: Connection[] = [];
  public wallets: Wallet[] = [];
  public assets: { assetId: string; assetName: string }[] = [];
  public totalRecords = 0;
  public records: {
    main: TxnSummaryMainRecord[];
    interacting_address: TxnSummaryInteractingAddressRecord[];
  } = {
    main: [],
    interacting_address: [],
  };

  public totals = [
    { name: 'Total Estimated NET Value', value: 0, key: 'totalUniqueNetFmv' },
    { name: 'Total Transactions Count', value: 0, key: 'totalUniqueTxns' },
    {
      name: 'Uncategorized Txn Lines',
      value: 0,
      key: 'totalUncategorized',
      sub: true,
      tooltip:
        'A single txn may have multiple lines. Calculated as total number of Uncategorized lines across all txns in the filtered set. This may exceed Uncategorized Txn Count.',
    },
    {
      name: 'Full Txn Lines',
      value: 0,
      key: 'totalTxnsCount',
      sub: true,
      tooltip:
        'A single txn may have multiple lines. Calculated as total number of lines across all txns in the filtered set. This may exceed Txn Count.',
    },
    {
      name: 'Estimated Value',
      value: 0,
      key: 'netFmv',
      sub: true,
      tooltip: 'Calculated as estimated dollar inflows MINUS estimated dollar outflows ',
    },
  ];

  private previousPromises: {
    [key: string]: CancelablePromise<any> | null;
  } = {
    records: null,
    totals: null,
  };

  public dataSources = [
    {
      value: 'DW 1',
      id: 'bigquery',
    },
    {
      value: 'DW 2',
      id: 'postgres',
      disabled: true,
    },
  ];

  public dataSourceMode = '';

  async mounted() {
    this._initDataSourceModes();

    this._callRestEndpoint('totals');
    this._callRestEndpoint('records');
    this._getAssets();

    await this.$nextTick();
  }

  public get walletItems() {
    return [{ id: 'All', name: 'All Wallets' }, ...this.wallets.map((w) => ({ id: w.id, name: w.name }))];
  }

  public get assetItems() {
    return [{ assetId: 'All', assetName: 'All Assets' }, ...this.assets];
  }

  public getTabClass(view: string) {
    if (view === this.selectedViewId) {
      return 'tw-bg-neutral-500 tw-text-neutral-100 tw-px-4 tw-py-4 tw-font-medium tw-text-sm';
    } else {
      return 'tw-text-gray-500 hover:tw-text-gray-700 tw-px-4 tw-py-4 tw-font-medium tw-text-sm';
    }
  }

  public onDateChange(field: 'startDate' | 'endDate', date: string | null) {
    if (date === null || this._isValidDate(date)) {
      this.filters[field] = date;
    }
  }

  public onDatePresetChange(dates: string[]) {
    const [startDate, endDate] = dates;
    this.filters.startDate = startDate;
    this.filters.endDate = endDate;
  }

  public onMultiSelectFilterChange(values: string[], filterName: 'walletId' | 'assetId') {
    if (values.length === 0) {
      this.filters[filterName] = ['All'];
    } else if (values[values.length - 1] === 'All') {
      this.filters[filterName] = ['All'];
    } else if (values.includes('All')) {
      this.filters[filterName] = values.filter((w) => w !== 'All');
    } else {
      this.filters[filterName] = values;
    }
  }

  public onColumnFilterChange(filters: any) {
    this.columnFilters = filters;
    this._callRestEndpoint('totals');
    this._callRestEndpoint('records');
  }

  public onSortChange(sort: { id: string; asc: boolean }) {
    this.sort = {
      field: sort.id,
      order: sort.asc ? 'asc' : 'desc',
    };

    this._callRestEndpoint('records');
  }

  public onGridAction(actionData: Record<string, string>) {
    const directionMapping = {
      inflow_rule: TransactionDirection.Inbound,
      outflow_rule: TransactionDirection.Outbound,
    };
    const direction = directionMapping[actionData.action as keyof typeof directionMapping];
    const conditions: any = {
      direction,
      walletIds: actionData.wallet,
      afterDate: this.filters.startDate,
      beforeDate: this.filters.endDate,
    };

    const [walletFilter] = this.filters.walletId;
    // TODO: remove length limitation once supported by rules
    if (!actionData.wallet && this.filters.walletId.length <= 1 && walletFilter !== 'All') {
      conditions.walletIds = walletFilter;
    }

    const [assetFilter] = this.filters.assetId;
    // TODO: remove length limitation once supported by rules
    if (!actionData.wallet && this.filters.assetId.length <= 1 && assetFilter !== 'All') {
      conditions.assets = this.assets.find((a) => a.assetId === assetFilter)?.assetName;
    }

    if (actionData.address) {
      if (direction === TransactionDirection.Inbound) {
        conditions.fromAddress = actionData.address;
      } else if (direction === TransactionDirection.Outbound) {
        conditions.toAddress = actionData.address;
      }
    }

    this.ruleConditions = conditions;
    this.isOpenRulesModal = true;
  }

  public closeRulesModal() {
    this.isOpenRulesModal = false;
    this.ruleConditions = null;
  }

  public switchTabAndFilter(view: TxnSummaryViewType, filters?: Partial<TxnSummaryFilters>) {
    this.selectedViewId = view;
    this.resetFiltersAndPages();

    if (filters) {
      this.filters = { ...this.filters, ...filters };
    } else {
      this._callRestEndpoint('totals');
      this._callRestEndpoint('records');
    }
  }

  public onPageChange(page: any) {
    this.pagination.pageNumber = page;
    this._callRestEndpoint('records');
  }

  public onItemsPerPageChange(itemsPerPage: number) {
    this.pagination.pageSize = itemsPerPage;
    this._callRestEndpoint('records');
  }

  public onExportClick() {
    this._callRestEndpoint('export');
  }

  private async _getAssets() {
    const orgId = this.$store.state.currentOrg.id;
    const params = { datasource: this.dataSourceMode ?? 'bigquery' };
    const endpointUrl = getEndpointUrl(this.apiUrl, ['dashboard', orgId, 'txns_summary', 'assets'], params);

    const response = await axios.get(endpointUrl, {
      withCredentials: true,
    });

    if (response.status === 200) {
      this.assets = response.data.items;
    }
  }

  public resetFiltersAndPages(resetAll?: boolean) {
    if (resetAll) {
      this.filters = { ...this.defaultFilters };
    }

    this.totalRecords = 0;
    this.pagination.pageNumber = 1;

    this.columnFilters = undefined;
    this.sort = { field: '', order: 'desc' };

    this._initDataSourceModes();
  }

  private _isValidDate(dateString: string) {
    // Parse the date string into a Date object
    const dateObj = new Date(dateString);
    // Check if the date object is valid and the string matches the expected format
    return !isNaN(dateObj.getTime()) && /^\d{4}-\d{2}-\d{2}$/.test(dateString);
  }

  private _buildQueryFilters() {
    const tableFilters = [];
    const baseFilters = [];

    if (this.columnFilters) {
      for (const key of Object.keys(this.columnFilters)) {
        // the only non range filter
        if (key === 'interactingAddress' && this.columnFilters[key]?.length) {
          tableFilters.push(constructFilter(key, this.columnFilters[key]));
          continue;
        }

        tableFilters.push(...construcRangeFilter(key, this.columnFilters[key]));
      }
    }

    if (!this.filters.walletId.includes('All')) {
      baseFilters.push(constructFilter('walletId', this.filters.walletId));
    }

    if (!this.filters.assetId.includes('All')) {
      baseFilters.push(constructFilter('assetId', this.filters.assetId));
    }

    if (!this.filters.reconciliationStatus.includes('All')) {
      baseFilters.push(constructFilter('reconciliationStatus', this.filters.reconciliationStatus.toLowerCase()));
    }

    if (this.filters.startDate || this.filters.endDate) {
      const dateRange = [this.filters.startDate ?? undefined, this.filters.endDate ?? undefined];
      baseFilters.push(...construcRangeFilter('dateTime', dateRange));
    }

    const filters: any = {};
    if (tableFilters.length) filters.filters = tableFilters;
    if (baseFilters.length) filters.base_filters = baseFilters;

    return filters;
  }

  private async _callRestEndpoint(type: 'records' | 'totals' | 'export') {
    this.loadingStates[type]++;
    const orgId = this.$store.state.currentOrg.id;
    const params: any = {
      ...this._buildQueryFilters(),
      datasource: this.dataSourceMode ?? 'bigquery',
    };

    if (type === 'records') {
      params.pagination = this.pagination;
      if (this.sort?.field?.length) params.sort = this.sort;
    }

    const endpointUrl = getEndpointUrl(
      this.apiUrl,
      ['dashboard', orgId, 'txns_summary', this.selectedViewId, type],
      params
    );

    try {
      this.previousPromises[type]?.cancel();

      const originalPromise = axios.get(endpointUrl, {
        withCredentials: true,
      });

      const cancelablePromise = makeCancelable(originalPromise);
      this.previousPromises[type] = cancelablePromise;
      const response = await cancelablePromise;

      if (response.status === 200) {
        if (type === 'totals') this._handleTotalsEndpointResults(response.data);
        else if (type === 'records') this._handleRecordsEndpointResults(response.data);
        else if (type === 'export') this._handleExportEndpointResults(response.data);
      } else {
        this.showErrorSnackbar(`Error fetching transaction summary ${type}`);
      }
    } catch (error) {
      if (error instanceof CanceledError) {
        console.info('The promise was canceled');
      } else {
        console.error(error);
        this.showErrorSnackbar('Error Fetching Transaction Summaries ' + error);
      }
    } finally {
      this.loadingStates[type]--;
    }
  }

  private _handleTotalsEndpointResults(data: any) {
    this.totalRecords = data.totals.totalRecords ? Number(data.totals.totalRecords) : 0;

    for (const total of this.totals) {
      const value = data.totals ? Number(data.totals) : 0;
      total.value = isNaN(value) ? data.totals[total.key] : 0;
    }
  }

  private _handleRecordsEndpointResults(data: any) {
    this.records[this.selectedViewId] = data.items;
  }

  private _handleExportEndpointResults(response: any) {
    const blob = new Blob([response], { type: 'text/csv' });
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.download = `transactions_summary.csv`;
    link.click();
    window.URL.revokeObjectURL(url);
  }

  private _initDataSourceModes() {
    if (this.checkFeatureFlag('event-sourced-txns')) {
      this.dataSources.find((ds) => ds.id === 'postgres')!.disabled = false;
      this.dataSourceMode = 'postgres';
    } else {
      this.dataSources.find((ds) => ds.id === 'postgres')!.disabled = true;
      this.dataSourceMode = 'bigquery';
    }
  }

  @Watch('filters')
  @Watch('filters.reconciliationStatus')
  @Watch('filters.walletId')
  @Watch('filters.assetId')
  @Watch('filters.startDate')
  @Watch('filters.endDate')
  async filtersUpdated() {
    this._callRestEndpoint('totals');
    this._callRestEndpoint('records');
  }

  @Watch('$store.state.currentOrg.id')
  async orgIdUpdated() {
    this.records.interacting_address = [];
    this.records.main = [];
    this.resetFiltersAndPages(true);
    this._getAssets();
  }

  @Watch('dataSourceMode')
  public switchMode(mode: string) {
    this.dataSourceMode = mode;
    this._callRestEndpoint('totals');
    this._callRestEndpoint('records');
  }
}
