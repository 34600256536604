
















































































































































































import gql from 'graphql-tag';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { Connection, ConnectionCategory, ConnectionStatus, Contact, Providers, Wallet } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import AppLink from '@/components/AppLink.vue';
import UiAdvancedDropdown from '@/components/transactions/UiAdvancedDropdown.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import { TxnSummaryInteractingAddressRecord } from '@/models/txnsSummary';
import { stringifyParams } from '@/utils/endpointUrlUtil';
import numberUtils from '@/utils/numberUtils';

type FiatCurrency = { name: string; symbol: string };
type ExtendedInteractingAddressRecord = TxnSummaryInteractingAddressRecord & {
  accountingConnectionId?: string | null;
  contact?: string;
};

@Component({
  components: {
    UiAdvancedDropdown,
    UiButton,
    UiDataTable,
    UiDropdown,
    UiTooltip,
    AppLink,
  },
  apollo: {
    connections: {
      query: gql`
        query GetConnections($orgId: ID!) {
          connections(orgId: $orgId, overrideCache: true) {
            id
            provider
            isDisabled
            isDeleted
            category
            name
            feeAccountCode
            isDefault
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoadingConnections',
      update(data: { connections?: Connection[] }) {
        return (
          data.connections?.filter((x) => !x.isDeleted && x.category === ConnectionCategory.AccountingConnection) ?? []
        );
      },
    },
  },
})
export default class TxnSummaryInteractingAddress extends BaseVue {
  @Prop({ default: false }) isLoading?: boolean;
  @Prop({ default: [] }) items!: ExtendedInteractingAddressRecord[];
  @Prop() filters!: Record<string, unknown>;
  @Prop() wallets!: Wallet[];
  @Prop() gridActions!: { value: string; label: string }[];
  @Prop({ default: [] }) assets!: { assetId: string; assetName: string }[];

  public numberFormat = numberUtils.format;
  public isSaving = false;

  declare connections: Connection[]; // populated via apollo

  public get headers() {
    return [
      {
        id: 'interactingAddress',
        label: 'Interacting Address',
        defaultVisibility: true,
        sortable: true,
        filterable: true,
      },
      // Deposits
      {
        id: 'depositsTxnsCount',
        label: 'Total Count',
        groupLabel: 'Inflow Transaction Lines',
        defaultVisibility: true,
        textAlignment: 'right',
        sortable: true,
        filterable: true,
        rangeFilter: true,
      },
      {
        id: 'depositsUncategorized',
        label: `Uncategorized Count`,
        groupLabel: 'Inflow Transaction Lines',
        defaultVisibility: true,
        textAlignment: 'right',
        sortable: true,
        filterable: true,
        rangeFilter: true,
      },
      {
        id: 'depositsFmv',
        label: `Estimated Value (${this.currentFiat.name})`,
        groupLabel: 'Inflow Transaction Lines',
        defaultVisibility: true,
        textAlignment: 'right',
        sortable: true,
        filterable: true,
        rangeFilter: true,
      },
      // Withdrawals
      {
        id: 'withdrawalsTxnsCount',
        label: 'Total Count',
        groupLabel: 'Outflow Transaction Lines',
        defaultVisibility: true,
        textAlignment: 'right',
        sortable: true,
        filterable: true,
        rangeFilter: true,
      },
      {
        id: 'withdrawalsUncategorized',
        label: `Uncategorized Count`,
        groupLabel: 'Outflow Transaction Lines',
        defaultVisibility: true,
        textAlignment: 'right',
        sortable: true,
        filterable: true,
        rangeFilter: true,
      },
      {
        id: 'withdrawalsFmv',
        label: `Estimated Value (${this.currentFiat.name})`,
        groupLabel: 'Outflow Transaction Lines',
        defaultVisibility: true,
        textAlignment: 'right',
        sortable: true,
        filterable: true,
        rangeFilter: true,
      },
    ];
  }

  public get connectionList(): Connection[] {
    const contacts = this.contacts;
    let connections = this.connections ?? [];

    if (contacts?.some((x: any) => !x.accountingConnectionId || x.accountingConnectionId === 'Manual') ?? false) {
      const manualAccountingConnection = {
        id: 'Manual',
        provider: Providers.Manual,
        status: ConnectionStatus.Ok,
      };
      connections = connections.concat(manualAccountingConnection);
    }

    if (connections && connections.length > 0) {
      return connections;
    } else {
      return [];
    }
  }

  public get contacts(): Contact[] {
    return this.$store.getters['contacts/ENABLED_CONTACTS'];
  }

  public getContactName(contactId: string) {
    const contact = this.contacts.find((x) => x.id === contactId);
    return contact?.name ?? '';
  }

  public getContactsByConnection(accountingConnectionId: string) {
    return this.contacts.filter((x) => x.accountingConnectionId === accountingConnectionId);
  }

  public getAdvancedUiRef(address: string) {
    return address?.toLowerCase() + 'address';
  }

  public get walletIdByAddress() {
    return this.wallets.reduce((a, x) => {
      x.addresses?.forEach((y) => {
        y = y?.toLowerCase() ?? '';
        if (y && x.id) {
          if (!a[y]) {
            a[y] = [];
          }
          a[y?.toLowerCase()].push(x.id);
        }
      });
      return a;
    }, {} as Record<string, string[]>);
  }

  public get walletById() {
    return this.wallets.reduce((a, x) => {
      a[x.id as string] = x;
      return a;
    }, {} as Record<string, Wallet>);
  }

  public getWalletNameByAddress(address: string) {
    const walletIds = this.walletIdByAddress?.[address?.toLowerCase()];
    if (!walletIds) return;
    if (walletIds.length > 1) return 'Multiple Wallets';
    else return this.walletById?.[walletIds[0]]?.name;
  }

  public get currentFiat(): FiatCurrency {
    const baseCurrency = this.$store.state.currentOrg.baseCurrency ?? 'USDa';
    return (
      this.$store.getters['fiats/FIATS']?.find((fiat: FiatCurrency) => fiat.name === baseCurrency) ?? {
        name: baseCurrency,
        symbol: '$',
      }
    );
  }

  public getSplitAddress(address: string) {
    if (!address) return ['', ''];
    return [address.slice(0, -8), address.slice(-8)];
  }

  public onConnectionSelect(item: ExtendedInteractingAddressRecord, connection: string) {
    this.$set(item, 'accountingConnectionId', connection);
  }

  public onContactSelect(item: ExtendedInteractingAddressRecord, contact: string) {
    this.$set(item, 'contact', contact);
  }

  public async assignToContact(address: string, contactId: string) {
    const contact = this.contacts.find((c) => c.id === contactId);
    if (!contact) return;
    await this.saveContactAddress(contact, address);
  }

  public async saveContactAddress(contact: Contact, address: string) {
    this.isSaving = true;
    let addresses = (contact?.addresses as any[]) ?? [];

    addresses.push({ address });
    addresses = addresses.filter((item, index, array) => {
      return array.findIndex((obj) => obj.address === item.address) === index;
    });

    try {
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($orgId: ID!, $contactId: ID!, $addresses: [ContactAddressInput!]!) {
            setContactCoinAddresses(orgId: $orgId, contactId: $contactId, addresses: $addresses)
          }
        `,
        variables: {
          orgId: this.$store.state.currentOrg.id,
          contactId: contact.id,
          addresses: addresses.map((x) => ({ ...x, __typename: undefined })),
        },
      });
    } catch (e) {
      this.showErrorSnackbar('An error occurred');
      console.error(e);
    } finally {
      this.isSaving = false;
    }
  }

  public closeAdvDropdown(key: string) {
    (this.$refs[key] as any)._self.setOpen();
    // ((this.$refs[key] as Vue[])[0] as any)?.setOpen();
  }

  public getPathToTxns(item: TxnSummaryInteractingAddressRecord, column: string) {
    const path = '/transactions';

    const uncategorized = column.match(/[Uu]ncategorized/);
    const params: any = {
      txFilter: {
        walletsFilter: ['All'],
        categorizationFilter: uncategorized ? 'Uncategorized' : 'All',
        searchTokens: [item.interactingAddress], // backward compatibility with Transactions3
      },
      filters: {},
    };

    const addressFilterKey = column.match(/deposit/) ? 'from' : 'to';
    params.filters[addressFilterKey] = [item.interactingAddress];

    if (this.filters) {
      params.txFilter.walletsFilter = this.filters.walletId;
      params.txFilter.reconciliationFilter = this.filters.reconciliationStatus;
      if (this.filters.endDate) params.txFilter.pivotDate = this.filters.endDate;

      const selectedAssets = this.filters.assetId as any[];
      if (selectedAssets && !selectedAssets.includes('All')) {
        const tickers = this.assets.filter((a) => selectedAssets.includes(a.assetId)).map((a) => a.assetName);
        if (tickers) params.filters.tickers = tickers;
      }
    }

    const queryParams = stringifyParams(params);
    return `${path}?${queryParams}`;
  }

  public onActionSelect(record: ExtendedInteractingAddressRecord, action: string) {
    this.$emit('gridAction', { address: record.interactingAddress, action });
  }

  @Watch('items')
  onItemsChane() {
    if (!this.contacts?.length) return;
    for (const item of this.items) {
      const matchingContact = this.contacts.find((c) =>
        c.addresses.some((a) => a.address.toLowerCase() === item.interactingAddress.toLowerCase())
      );
      if (matchingContact) {
        this.$set(item, 'contact', matchingContact.id);
        this.$set(item, 'accountingConnectionId', matchingContact.accountingConnectionId);
      }
    }
  }
}
