
































import moment from 'moment-timezone';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import UiListItem from '@/components/ui/UiListItem.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';

enum EDatePreset {
  LAST_30_DAYS = 'Last 30 days',
  LAST_6_MONTHS = 'Last 6 months',
  THIS_MONTH = 'This month',
  THIS_QUARTER = 'This quarter',
  THIS_YEAR = 'This year',
  LAST_MONTH = 'Last month',
  LAST_QUARTER = 'Last quarter',
  LAST_YEAR = 'Last year',
}

type PresetId = keyof typeof EDatePreset;

@Component({ components: { UiSelect2, UiListItem } })
export default class UiDatePreset extends Vue {
  @Prop()
  public readonly label?: string;

  @Prop({ default: 'LAST_30_DAYS' })
  public value?: PresetId;

  @Prop({ default: true })
  public readonly nativeDates!: boolean;

  @Prop({ default: false })
  public readonly minimalSelector!: boolean;

  @Prop()
  public excludeValues?: PresetId[];

  private readonly presetValues = [
    ...Object.keys(EDatePreset).map((key) => ({ id: key as PresetId, name: EDatePreset[key as PresetId] })),
  ];

  public isOpenedSelect = false;
  public selectedPreset?: PresetId = 'LAST_30_DAYS';

  public props = {
    value: this.value,
  };

  public get visiblePresetValues() {
    return this.presetValues.filter((preset) => !this.excludeValues?.includes(preset.id as PresetId));
  }

  public get presetLabel() {
    return this.selectedPreset ? EDatePreset[this.selectedPreset] : '';
  }

  public onPresetSelect(value: PresetId) {
    if (this.minimalSelector) {
      this.$set(this, 'selectedPreset', value);
      this.$emit('change', value);
      this.isOpenedSelect = false;
    }

    let startDate = moment();
    let endDate = moment();

    switch (value) {
      case 'LAST_30_DAYS':
        startDate = startDate.subtract(30, 'days').startOf('day');
        break;
      case 'THIS_MONTH':
        startDate = startDate.startOf('month');
        break;
      case 'THIS_QUARTER':
        startDate = startDate.startOf('quarter');
        break;
      case 'THIS_YEAR':
        startDate = startDate.startOf('year');
        break;
      case 'LAST_MONTH':
        startDate = startDate.startOf('month').subtract(1, 'month');
        endDate = moment(startDate).endOf('month');
        break;
      case 'LAST_QUARTER':
        startDate = startDate.startOf('quarter').subtract(1, 'quarter');
        endDate = moment(startDate).endOf('quarter');
        break;
      case 'LAST_6_MONTHS':
        startDate = startDate.startOf('month').subtract(5, 'month');
        break;
      case 'LAST_YEAR':
        startDate = startDate.startOf('year').subtract(1, 'year');
        endDate = moment(startDate).endOf('year');
        break;
    }

    const dateRange = this.nativeDates
      ? [startDate.toDate(), endDate.toDate()]
      : [startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')];

    this.$emit('input', dateRange);
  }

  @Watch('value')
  onValueChange() {
    if (this.minimalSelector && this.value && this.value !== this.selectedPreset) {
      this.onPresetSelect(this.value);
    }
  }
}
