









import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Location as VueRouterLocation } from 'vue-router';

import { requestParentToNavigate } from '@/utils/iframeMessageRequester';

@Component
export default class AppLink extends Vue {
  @Prop({ required: true })
  to!: string | VueRouterLocation;

  public parentURL = process.env.VUE_APP_PARENT_WEB_APP;
  public hasParentApp = window.self !== window.top;

  onRouterLinkClick() {
    if (typeof this.to === 'string') {
      const [path, query] = this.to.split('?');
      requestParentToNavigate(path, query);
    } else {
      requestParentToNavigate(this.to.path ?? '/', this.to.query);
    }
  }
}
